@import 'lowcode/common/css/devices.scss';

.editor-temp.editor-text-richtext {
    display: block;
}
.editor-text-richtext {
    position: relative;
    --editor-text-richtext-color: #000;
    --editor-text-richtext-bg-color: transparent;
    color: var(--editor-text-richtext-color);
    background-color: var(--editor-text-richtext-bg-color);
    text-align: center;
    a, h1, h2, h3, h4, h5, h6 {
        color: inherit;
    }
    ul {
        padding-left: 20px;
    }
    ul li {
        list-style-type: disc;
    }
    ol {
        padding-left: 20px;
    }
    ol li {
        list-style: auto;
    }
    .richtext-title {
        margin: 0 auto;
        max-width: 1440px;
        padding: 0 120px;
        margin: 0 auto;
        font-family: var(--ff-woff2-M);
        line-height: 26px;
        font-size: 16px;
        white-space: pre-line;
    }
    strong {
        font-family: var(--ff-woff2-B);
    }
    table {
        width: 100%;
    }
    td, th {
        border: 1px solid #ddd;
        padding: 2px 8px;
    }
    th {
        background-color: #f4f4f4;
    }
    @include devices(desktop-max) {
    }
    @include devices(pad) {
        .richtext-title {
            width: auto;
            padding: 0 48px;
            line-height: 24px;
        }
    }
    @include devices(h5) {
        .richtext-title {
            padding: 0 24px;
        }
    }
}
.editor-text-richtext a {
    color: #3f68e0;
}
.editor-text-richtext a:hover {
    color: #3f68e0;
    text-decoration: underline;
}
.editor-text-richtext p {
    margin-bottom: 16px;
}
.editor-text-richtext p:last-child {
    margin-bottom: 0;
}
.editor-text-richtext.position-left {
    text-align: left;
}
.editor-text-richtext.position-right {
    text-align: right;
}